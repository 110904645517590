import React from "react"
// import PropTypes from "prop-types"
import Layout from "../components/layout"
import Seo from "../components/seo"

import { GatsbyImage } from "gatsby-plugin-image";
import defFI from '../../content/assets/images/regga-sig-pp.png'

// Components
import { Link, graphql } from "gatsby"

const Tags = ({ pageContext, data, location }) => {
  const { tag } = pageContext
  const posts = data.posts.edges
  const defImg = data.defImg?.childImageSharp?.resize?.src
  const tagHeader = `${tag}`,
  quotes = data.quotes?.edges

  return (
    <Layout location={location} title={tagHeader}>
      <Seo
        title={`Post tag: ${tagHeader}`}
        description={`${tag} is a post tag at Regga @reggarantai`}
        image={defFI || ''}
        url={`/${tag}/`}
        meta={[
          {
            name: `keywords`,
            content: [tag,'regga', 'regga rantai', 'reggarantai','@reggarantai','gerakan jemari akhir pekan'].join(",")
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image:width`,
            content: `907`,
          },
          {
            property: `og:image:height`,
            content: `907`,
          },
          {
            property: `og:image:type`,
            content: `image/png`,
          }
        ]}
      />
      <article className="blog-post">
        <div style={{
          backgroundColor: '#000',
          backgroundSize: 'cover',
          backgroundImage: `url(${defImg || ''})`,
          backgroundPosition: 'center center'
        }}>
          <div id="head-bg">
            <br /><br />
            <header style={{paddingBottom:'334px'}}>
              <h4>Post tag</h4>
              <h1 className="animate__animated animate__pulse" itemProp="headline">{tagHeader}</h1>
              <div className="line"/>
            </header>
          </div>
        </div>

        <div id="home-body">
          <section id="articles" className="section">
            <div id="latest-article">
              <div className="container cards">
                <div className="row">
                  {
                    posts.map(({ node}) => {
                      const title = node.frontmatter.title || node.fields.slug
                        return (
                          <div key={node.fields.slug} className="card">
                            <div className="card-inner">
                              <Link to={node.fields.slug}>
                                <GatsbyImage
                                  alt={node.frontmatter.title}
                                  image={node.frontmatter.featuredImage.childImageSharp.gatsbyImageData}
                                  objectFit="cover"
                                  objectPosition="50% 50%" />
                                <div className="card-body">
                                  <small className="card-date">{`${node.frontmatter.hijriah}`}</small>
                                  <h3>{title}</h3>
                                  <p className="card-p"
                                    dangerouslySetInnerHTML={{
                                      __html: node.frontmatter.description || node.excerpt,
                                    }}
                                  />
                                </div>
                              </Link>
                            </div>
                          </div>
                        );
                      })
                  }
                </div>
              </div>
            </div>

          </section>
        </div>

      </article>

      {
        quotes && (
          <section id="quotes" className="section" style={{
            backgroundSize: 'cover',
            backgroundImage: `url(${defImg || ''})`,
            backgroundColor: '#fff',
            backgroundPosition: 'top center'
          }}>
            <div className="overlay"></div>
            <div className="container section-title section-title-white"><div className="row"><div className="col-sm-12">
                <h2>Kutipan</h2>
            </div></div></div>
            <div className="container cards cards-transparent">
              <div className="row" data-sal="fade"
              data-sal-duration="2000"
              data-sal-easing="ease">
                {
                  quotes?.map(({ node}) => {
                    return (
                      <div key={node.fields.slug} className="card3">
                        <div className="card-inner">
                          <Link to={node.fields.slug}>
                            <GatsbyImage
                              image={node.frontmatter.featuredImage.childImageSharp.gatsbyImageData}
                              objectFit="cover"
                              objectPosition="50% 50%" />
                          </Link>
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            </div>

          </section>
        )
      }
    </Layout>
  );
}
export default Tags

export const pageQuery = graphql`query ($tag: String) {
  posts: allMdx(
    limit: 1000
    filter: {frontmatter: {tags: {in: [$tag]}, draft: {eq: false}}}
    sort: {fields: [frontmatter___date], order: DESC}
  ) {
    edges {
      node {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          hijriah
          description
          postType
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 460, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
  quotes: allMdx(
    filter: {frontmatter: {postType: {eq: "kutipan"}}}
    limit: 4
    sort: {fields: [frontmatter___date], order: DESC}
  ) {
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 640, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
  defImg: file(relativePath: {eq: "images/regga-coffee.jpg"}) {
    childImageSharp {
      resize(width: 10) {
        src
      }
    }
  }
}
`
